import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import Card from '../../components/Card';
import CreateCard from '../../components/forms/CreateCard';
import { useUser } from '../../contexts/userContext';
import { useCard } from '../../contexts/cardContext';
import { useColumns } from '../../contexts/columnsContext';
import { Droppable } from 'react-beautiful-dnd';
import debounce from 'lodash.debounce';
import './style.css';

function Column(props) {
  const { cards, listaCardsFiltrados } = useCard();
  const { orderBy, isAscending, filteredColumns } = useColumns();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredCards, setFilteredCards] = useState([]);
  const [displayedCards, setDisplayedCards] = useState(14);
  const columnBodyRef = useRef(null);

  const handleAddCardClick = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);








  // useEffect(() => {
  //   let filtered = listaCardsFiltrados.filter(card => card.column_id === props.columnData.id);
  //   setFilteredCards(filtered);
  // }, [listaCardsFiltrados]);

  useEffect(() => {
    let filtered = listaCardsFiltrados.filter(card => card.column_id === props.columnData.id);
    setFilteredCards(filtered);
  }, [listaCardsFiltrados, props.columnData.id, cards]);
  






  // Ordenação dos cartões
  const sortedCards = useMemo(() => {
    return [...filteredCards].sort((a, b) => {
      let comparison = 0;
      switch (orderBy) {
        case 'nome':
          comparison = a.name.localeCompare(b.name);
          break;
        case 'dataStatus':
          comparison = new Date(a.status_date) - new Date(b.status_date);
          break;
        case 'dataCreate':
          comparison = new Date(a.created_at) - new Date(b.created_at);
          break;
        case 'dataUpdate':
          comparison = new Date(a.updated_at) - new Date(b.updated_at);
          break;
        case 'value':
          comparison = a.cost_value - b.cost_value;
          break;
        case 'task':
          const daysA = a.dias_para_proxima_tarefa ?? Infinity; // Se for null ou vazio, considera Infinity
          const daysB = b.dias_para_proxima_tarefa ?? Infinity;
          comparison = daysA - daysB;
          break;
        default:
          comparison = 0;
      }
      return isAscending ? comparison : -comparison;
    });
  }, [filteredCards, orderBy, isAscending]);


  // Atualização do total de cartões e valor total
  const totalCostValue = useMemo(() => filteredCards.reduce((acc, card) => acc + parseFloat(card.cost_value || 0), 0), [filteredCards]);
  const cardCount = filteredCards.length;

  // Função para carregar mais cartões ao rolar
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = columnBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setDisplayedCards(prevDisplayed => prevDisplayed + 10);
      }
    };

    const columnBodyElement = columnBodyRef.current;
    if (columnBodyElement) {
      columnBodyElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (columnBodyElement) {
        columnBodyElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);







  return (
    <Droppable droppableId={String(props.columnData.id)}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{
            backgroundColor: snapshot.isDraggingOver ? '#c8f3bf' : '#F0F2F5',
          }}
          className='column-container'
        >
          <div className='column-header'>
            <div className='column-title'>
              <label>{props.columnData.name}</label>
              <button className='btn-add-new-card' onClick={handleAddCardClick}>+</button>
            </div>
            <div className='column-info'>
              <label>{cardCount} Cards</label>
              <label>R$ {totalCostValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</label>
            </div>
          </div>
          <div className='column-body' ref={columnBodyRef}>
            {sortedCards.slice(0, displayedCards).map((card, index) => (
              <Card key={card.card_id} cardData={card} index={index} />
            ))}
            {provided.placeholder}
          </div>
          <div className='column-footer'>{props.columnData.id}</div>
          {isModalOpen && <CreateCard columnId={props.columnData.id} onClose={handleCloseModal} />}
        </div>
      )}
    </Droppable>
  );
}

export default Column;
