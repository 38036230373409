import React, { useState, useEffect } from 'react';
import Header from '../../../components/Header';
import CriarAtualizarTipologia from '../../../components/CriarAtualizarTipologia';
import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig'; // Supondo que o URL da API esteja configurado aqui
import './style.css';

import { MdAdd } from 'react-icons/md'; // Importando o ícone de adicionar do Material Icons

import { useColumns } from '../../../contexts/columnsContext';
function ProjetosPage() {

  const { currentTipologia, setCurrentTipologia } = useColumns();

  const [tipologias, setTipologias] = useState([]); // Lista de tipologias
  const [isCreating, setIsCreating] = useState(false); // Controle do modal de criação

  // Função para buscar tipologias ao carregar a página
  useEffect(() => {
    const fetchTipologias = async () => {
      try {
        const response = await axios.get(`${apiUrl}/tipologias`);
        setTipologias(response.data); // Atualiza o estado com as tipologias recebidas
      } catch (error) {
        console.error('Erro ao buscar tipologias:', error);
      }
    };

    fetchTipologias(); // Chama a função ao montar o componente
  }, []);


  const updateCurrentTipologia = (tipologia) => {
    setCurrentTipologia(tipologia)
    handleNewProjectClick()
  };

  const handleNewProjectClick = () => {
    setIsCreating(true); // Abre o modal de criação
  };

  const handleCloseModal = () => {
    setIsCreating(false); // Fecha o modal de criação
  };

  return (
    <div className='projetos-page-container'>
      <Header />
      <div className='projetos-header'>
        <MdAdd className='btn-novo-projeto' onClick={() => updateCurrentTipologia(null)} />
      </div>

      <div className='projetos-list'>
        {tipologias.length === 0 ? (
          <p>Nenhuma tipologia disponível.</p>
        ) : (
          tipologias.map((tipologia, index) => (
            <div key={index} className='tipologia-card' onClick={() => updateCurrentTipologia(tipologia)}>
              <div className='croqui-tipologia-container'>
              </div>
              <h3 className='descricao-tipologia'>{tipologia.descricao}</h3>
            </div>
          ))
        )}
      </div>

      {/* Modal de criação de projetos */}
      {isCreating && <CriarAtualizarTipologia onClose={handleCloseModal} />}
    </div>
  );
}

export default ProjetosPage;
