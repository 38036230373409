import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from '../../config/apiConfig';
import './style.css';
import { useUser } from '../../contexts/userContext';
import { FaChartLine, FaMedal } from 'react-icons/fa';

import img01 from '../../assets/img-01.JPG';
import img03 from '../../assets/banner02.png';
import img04 from '../../assets/banner.png';


const BemVindo = () => {
  const { user, empresa } = useUser();

  const [status, setStatus] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [shouldFadeOut, setShouldFadeOut] = useState(false);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axios.get(`${apiUrl}/process-columns/system-status`);
        setStatus(response.data);
        //console.log(response.data);
      } catch (error) {
        console.error("Error fetching system status", error);
      }
    };

    fetchStatus();
  }, []);

  useEffect(() => {
    if (!status || (status.message && status.message !== '') || status.maintenance_mode) {
      return;
    }

    const autoCloseTimer = setTimeout(() => {
      setShouldFadeOut(true);
    }, 600); // Espera 2 segundos antes de iniciar a animação de saída

    return () => clearTimeout(autoCloseTimer);
  }, [status]);

  useEffect(() => {
    if (!status || (status.message && status.message !== '') || status.maintenance_mode) {
      return;
    }

    if (shouldFadeOut) {
      const fadeOutTimer = setTimeout(() => {
        setIsVisible(false);
      }, 500); // Tempo da animação para desaparecer (500ms)

      return () => clearTimeout(fadeOutTimer);
    }
  }, [shouldFadeOut, status]);

  useEffect(() => {
    if (!isVisible) {
      const modalElement = document.querySelector('.bem-vindo-modal');
      if (modalElement) {
        modalElement.remove();
      }
    }
  }, [isVisible]);

  if (!status || !isVisible) return null;

  const isMaintenance = status.maintenance_mode;

  return (
    <div className={`bem-vindo-modal ${shouldFadeOut ? 'fade-out' : 'fade-in'} ${isMaintenance ? 'maintenance' : ''}`}>
      <div className={`column-boas-vindas-container`}>

      {!isMaintenance && status.message && (
            <button
              className='btn-close-bem-vindo'
              aria-label="Fechar"
              onClick={() => {
                setShouldFadeOut(true);
                setTimeout(() => setIsVisible(false), 500); // Fecha o modal após a animação
              }} // Inicia a animação de saída ao clicar no botão
            >
              X
            </button>
          )}
        <div className={`bem-vindo-container ${shouldFadeOut ? 'slide-out' : 'slide-in'} ${isMaintenance ? 'maintenance-container' : ''}`}>

          <div className='bem-vindo-content'>
          {/* {!isMaintenance && status.message && (
            <button
              className='btn-close-bem-vindo'
              aria-label="Fechar"
              onClick={() => {
                setShouldFadeOut(true);
                setTimeout(() => setIsVisible(false), 500); // Fecha o modal após a animação
              }} // Inicia a animação de saída ao clicar no botão
            >
              X
            </button>
          )} */}
            <div className={`bem-vindo-text ${isMaintenance ? 'maintenance-text' : ''}`}>
              
              <h2 style={{ color: isMaintenance ? 'white' : '' }} className='title-bem-vindo-fixed'>{isMaintenance ? "Sistema em Manutenção" : "Bem-vindo!"}</h2>



              <p style={{ color: isMaintenance ? 'white' : '' }} className='maintenance-message'>{status.message}</p>
              {isMaintenance && (
                <div className='maintenance-footer'>
                  <p>Por favor, tente novamente mais tarde.</p>
                </div>
              )}

            </div>

            <div className='img-container-bem-vindo-botton'>

              <img src={img03} alt="Bem-vindo" className='welcome-icon' />

            </div>




          </div>
        </div>
      </div>
    </div>
  );
};

export default BemVindo;
