import React, { useEffect, useState } from 'react';
import './style.css';
import { MdSave, MdExitToApp } from 'react-icons/md';
import axios from 'axios';
import { apiUrl } from '../../config/apiConfig';
import { useUser } from '../../contexts/userContext';
import Frame from '../Frame'; // Importando o novo componente Frame
import { useColumns } from '../../contexts/columnsContext';

function CriarAtualizarTipologia({ onClose }) {
  const { user, empresa } = useUser();
  const { currentTipologia, setCurrentTipologia } = useColumns();

  const [tipologia_id, setTipologia_id] = useState('');
  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [largura, setLargura] = useState(1000);
  const [altura, setAltura] = useState(1000);

  const [frames, setFrames] = useState([
    { id: 1, tipo: 'marco', z_index: 1, largura: 2000, altura: 1000, pos_x: 0, pos_y: 0, color: 'red'},
    { id: 2, tipo: 'folha', z_index: 3, largura: 989, altura: 912, pos_x: 44, pos_y: 44, color: 'blue' },
    { id: 3, tipo: 'folha', z_index: 2, largura: 989, altura: 912, pos_x: 967, pos_y: 44 , color: 'green'},
  ]);

  const handleSave = async () => {
    try {
      if (tipologia_id) {
        const response = await axios.put(`${apiUrl}/tipologias/${tipologia_id}`, {
          empresa_id: empresa.id,
          codigo,
          descricao,
          largura: parseFloat(largura),
          altura: parseFloat(altura),
        });
        console.log('Tipologia atualizada:', response.data);
      } else {
        const response = await axios.post(`${apiUrl}/tipologias`, {
          empresa_id: empresa.id,
          codigo,
          descricao,
          largura: parseFloat(largura),
          altura: parseFloat(altura),
        });
        console.log('Nova tipologia criada:', response.data);
      }
      onClose(); // Fecha o modal após salvar
    } catch (error) {
      console.error('Erro ao salvar a tipologia:', error);
    }
  };

  useEffect(() => {
    if (currentTipologia && currentTipologia.id) {
      setTipologia_id(currentTipologia.id);
      setCodigo(currentTipologia.codigo);
      setDescricao(currentTipologia.descricao);
      setLargura(currentTipologia.largura);
      setAltura(currentTipologia.altura);
    }
  }, [currentTipologia]);

  return (
    <div className="modal-backdrop">
      <div className="main-tools-project-container">
        <MdExitToApp className="icon-main-tools-project" onClick={onClose} />
        <MdSave className="icon-main-tools-project" onClick={handleSave} />
      </div>

      <div className="modal-container">
        <div className="view-project-container">
          {/* Renderizando o Frame para cada item na lista */}
          {frames.map((frame) => (
            <Frame
              key={frame.id}
              frame={frame}
            />
          ))}
        </div>

        <div className="tools-project-container">
          <label>
            Código:
            <input
              type="text"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
            />
          </label>
          <label>
            Descrição:
            <input
              type="text"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </label>
          <label>
            Largura:
            <input
              type="text"
              value={largura}
              onChange={(e) => setLargura(e.target.value)}
            />
          </label>
          <label>
            Altura:
            <input
              type="text"
              value={altura}
              onChange={(e) => setAltura(e.target.value)}
            />
          </label>
        </div>
      </div>
    </div>
  );
}

export default CriarAtualizarTipologia;
