import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import axios from 'axios';
import { apiUrl } from '../../config/apiConfig';
import PreviewCard from '../../components/PreviewCard';
import './style.css';

import { useUser } from '../../contexts/userContext';


const locales = {
  'pt-BR': require('date-fns/locale/pt-BR'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Calendario = ({ closeModal }) => {

  const { user } = useUser();

  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchAllPendingTasks();
  }, []);



  // const fetchAllPendingTasks = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/card/tasks/all-pending?userId=${user.id}`);
  //     setTasks(
  //       response.data.map((task) => ({
  //         id: task.task_id,
  //         title: task.description,
  //         start: new Date(task.due_date),
  //         end: new Date(task.due_date),
  //         allDay: true,
  //         cardId: task.card_id,
  //         cardName: task.card_name,
  //         ...task, // Inclui todas as propriedades carregadas no join
  //       }))
  //     );
  //   } catch (error) {
  //     console.error('Erro ao buscar tarefas pendentes:', error);
  //   }
  // };
  


  const fetchAllPendingTasks = async () => {
    try {
      const response = await axios.get(`${apiUrl}/card/tasks/all-pending?userId=${user.id}`);
  
      setTasks(
        response.data.map((task) => {
          // Converte a data e define o horário para meia-noite no UTC
          const dueDate = new Date(task.due_date);
          const adjustedDate = new Date(
            dueDate.getUTCFullYear(),
            dueDate.getUTCMonth(),
            dueDate.getUTCDate(),
            0, 0, 0 // garante que o horário seja 00:00
          );
  
          return {
            id: task.task_id,
            title: task.description,
            start: adjustedDate,
            end: adjustedDate,
            allDay: true,
            cardId: task.card_id,
            cardName: task.card_name,
            ...task,
          };
        })
      );
  
    } catch (error) {
      console.error('Erro ao buscar tarefas pendentes:', error);
    }
  };

  








  
  

  // Função para mapear as propriedades do evento selecionado
  const formatTaskData = (event) => {
    return {
      card_id: event.cardId || event.card_id,
      name: event.cardName || event.card_name,
      city: event.city || '',
      state: event.state || '',
      fone: event.fone || '',
      email: event.email || '',
      column_id: event.column_id,
      entity_id: event.entity_id,
      empresa_id: event.empresa_id,
      created_at: event.created_at,
      updated_at: event.updated_at,
      document_number: event.document_number,
      cost_value: event.cost_value,
      sale_value: event.sale_value,
      status: event.status,
      status_date: event.status_date,
      potencial_venda: event.potencial_venda,
      cpf: event.cpf,
      origem: event.origem,
      produto: event.produto,
      motivo_venda_perdida: event.motivo_venda_perdida,
      nivel_prioridade: event.nivel_prioridade,
      second_document_number: event.second_document_number,
      pedido_number: event.pedido_number,
      etiqueta_id: event.etiqueta_id,
      block_column: event.block_column,
      etapa_producao: event.etapa_producao,
      implantado_em: event.implantado_em,
      endereco: event.endereco,
      participante_id: event.participante_id,
      contract_signed: event.contract_signed,
      contract_signed_at: event.contract_signed_at,
      signed_by_user_id: event.signed_by_user_id,
    };
  };

  const handleSelectEvent = (event) => {
    const formattedTask = formatTaskData(event);
    setSelectedTask(formattedTask);
  };

  // Verifica se a data é válida antes de chamar `format`
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  return (
    <div className="calendar-modal">
      <div className="calendar-header">
        <button className="close-button-calendario" onClick={closeModal}>
          Fechar
        </button>
      </div>
      <div className='calendario-style'>
        <Calendar
          localizer={localizer}
          events={tasks}
          startAccessor="start"
          endAccessor="end"
          style={{ width: 'calc(100vw - 20px', height: 'calc(100vh - 48px' }}
          onSelectEvent={handleSelectEvent}
          messages={{
            next: 'Próximo',
            previous: 'Anterior',
            today: 'Hoje',
            month: 'Mês',
            week: 'Semana',
            day: 'Dia',
            agenda: 'Agenda',
            date: 'Data',
            time: 'Hora',
            event: 'Evento',
            noEventsInRange: 'Nenhum evento neste período.',
            showMore: (total) => `+ Ver mais (${total})`,
            allDay: 'Dia inteiro',
            dateHeaderFormat: 'dd/MM/yyyy',
          }}
        />

      </div>

      {/* Detalhes da tarefa selecionada */}
      {selectedTask && (
        <div className="task-details-modal">
          <div className="task-details-container">
            <button className="close-button-task-details" onClick={() => setSelectedTask(null)}>
              Fechar
            </button>
            {/* Passando todas as informações corretamente para o PreviewCard */}
            <PreviewCard key={selectedTask.card_id} cardData={selectedTask} />
          </div>
        </div>
      )}

    </div>
  );
};

export default Calendario;
