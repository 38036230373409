import React, { useEffect, useRef, useState } from 'react';
import './style.css';

function Frame({ frame }) {
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      setContainerSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [containerRef]);

  if (!frame.largura || !frame.altura) {
    return <p>Insira largura e altura válidas para visualizar a esquadria.</p>;
  }

  const proporcao = 0.5;

  console.log(proporcao)

  const estiloFrame = {
    width: `${frame.largura * proporcao}px`,
    height: `${frame.altura * proporcao}px`,
    border: '2px solid #ccc',
    backgroundColor: 'transparent',
    position: 'absolute',
    left: `${frame.pos_x * proporcao}px`,
    top: `${frame.pos_y * proporcao}px`,
    zIndex: frame.z_index,
    backgroundColor: frame.color,
  };

  return (
    <div ref={containerRef} className="frame-container" style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <div style={estiloFrame}>
        <div

        />
      </div>
    </div>
  );
}

export default Frame;
