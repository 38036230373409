import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { apiUrl } from '../../config/apiConfig'; // Certifique-se de que este arquivo esteja configurado corretamente
import './style.css';

const GoogleCalendario = () => {

  const [value, setValue] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // Estado para o evento selecionado
  const [newEvent, setNewEvent] = useState({
    summary: '',
    description: '',
    startDateTime: '',
    endDateTime: '',
  });

  const [eventDates, setEventDates] = useState([]);

  // Código para trocar token de forma manual
  const exchangeCodeForToken = async () => {
    const code = '4/0AQlEd8yGh25i741nSvgMNrl-TKIg4NEnXJKVvYgp5CwfkD5CkHmANu9MgaVES-OAwNAzhA'; // Seu código de autorização

    try {
      const response = await axios.post('http://localhost:3001/google-calendar/exchange-code', { code });
      if (response.data.success) {
        //console.log('Token trocado e salvo com sucesso!');
      } else {
        console.error('Erro ao trocar o código pelo token:', response.data.message);
      }
    } catch (error) {
      console.error('Erro ao realizar a troca do código pelo token:', error);
    }
  };

  const fetchGoogleCalendarEvents = async () => {
    try {
      const response = await axios.get(`${apiUrl}/google-calendar/events`);
      if (response.data && response.data.data) {
        const events = response.data.data;

        // Extraímos as datas dos eventos
        const eventDates = events.map(event => ({
          date: new Date(event.start.dateTime || event.start.date),
          summary: event.summary,
          description: event.description || '',
        }));

        // Definimos os eventos e as datas no estado
        setEvents(events);
        setEventDates(eventDates);
      } else {
        setEvents([]);
        setEventDates([]);
      }
    } catch (error) {
      console.error('Erro ao buscar eventos do Google Calendar:', error);
      setEvents([]);
      setEventDates([]);
    }
  };

  useEffect(() => {
    fetchGoogleCalendarEvents(); // Buscar eventos ao carregar o componente
  }, []);

  // Função para lidar com a criação de novos eventos
  const handleAddEvent = async () => {
    const event = {
      summary: newEvent.summary,
      description: newEvent.description,
      start: {
        dateTime: newEvent.startDateTime,
        timeZone: 'America/Sao_Paulo',
      },
      end: {
        dateTime: newEvent.endDateTime,
        timeZone: 'America/Sao_Paulo',
      },
    };

    try {
      const response = await axios.post(`${apiUrl}/google-calendar/event`, event);
      //console.log('Evento criado:', response.data);
      fetchGoogleCalendarEvents(); // Atualiza a lista de eventos após adicionar um novo evento
    } catch (error) {
      console.error('Erro ao criar evento no Google Calendar:', error);
    }
  };

  // Função para lidar com a mudança nos campos do formulário
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };

  // Função para lidar com o clique no evento
  const handleEventClick = (event) => {
    setSelectedEvent(event); // Define o evento selecionado
  };

  // Função para fechar a div com detalhes do evento
  const closeEventDetails = () => {
    setSelectedEvent(null); // Limpa o evento selecionado
  };

  return (
    <div className="google-calendar-modal">
      <div className="google-calendar-container">
        <h1>Google Calendar</h1>

        <div>
          <button onClick={exchangeCodeForToken}>Trocar código por token</button>
        </div>

        {/* Formulário para adicionar um novo evento */}
        <div className="new-event-form">
          <h2>Adicionar Novo Evento</h2>
          <input
            type="text"
            name="summary"
            placeholder="Título do evento"
            value={newEvent.summary}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="description"
            placeholder="Descrição"
            value={newEvent.description}
            onChange={handleInputChange}
          />
          <input
            type="datetime-local"
            name="startDateTime"
            placeholder="Início do evento"
            value={newEvent.startDateTime}
            onChange={handleInputChange}
          />
          <input
            type="datetime-local"
            name="endDateTime"
            placeholder="Fim do evento"
            value={newEvent.endDateTime}
            onChange={handleInputChange}
          />
          <button onClick={handleAddEvent}>Adicionar Evento</button>
        </div>

        <Calendar
          onChange={setValue}
          value={value}
          tileContent={({ date, view }) => {
            // Se houver um evento nesta data, mostre o nome do evento
            const eventsForDate = eventDates.filter(eventDate => eventDate.date.toDateString() === date.toDateString());
            return (
              <div>
                {eventsForDate.map((event, index) => (
                  <div key={index} onClick={() => handleEventClick(event)} className="event-summary">
                    {event.summary}
                  </div>
                ))}
              </div>
            );
          }}
        />

        {/* Modal para exibir os detalhes do evento quando um evento é clicado */}
        {selectedEvent && (
          <div className="event-details-modal">
            <div className="event-details">
              <h2>Detalhes do Evento</h2>
              <p><strong>Nome:</strong> {selectedEvent.summary}</p>
              <p><strong>Descrição:</strong> {selectedEvent.description}</p>
              <p><strong>Data:</strong> {selectedEvent.date.toDateString()}</p>
              <button onClick={closeEventDetails}>Fechar</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GoogleCalendario;
