import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl, fileApiUrl } from '../../config/apiConfig';
import './style.css';

import { useColumns } from '../../contexts/columnsContext';
import LogoFaktory from '../../assets/logo-faktory.png';

const Faktory = ({ cardData }) => {
  const { setShowFaktoryModule } = useColumns();

  const [participanteFaktory, setParticipanteFaktory] = useState({});
  const [pedidoVendaFaktory, setPedidoVendaFaktory] = useState({});
  const [historicoPedidoVendaFaktory, setHistoricoPedidoVendaFaktory] = useState([]);
  const [itensPedidoVendaFaktory, setItensPedidoVendaFaktory] = useState([]);

  useEffect(() => {
    const moduloFaktory = async () => {
      try {
        const pedidoVendaResponse = await axios.get(`${fileApiUrl}/faktory/pedidovenda/${parseInt(cardData.pedido_number)}`);
        setPedidoVendaFaktory(pedidoVendaResponse.data);

        const participanteResponse = await axios.get(`${fileApiUrl}/faktory/participante/${parseInt(cardData.pedido_number)}`);
        setParticipanteFaktory(participanteResponse.data);

        const historicoPedidoVendaResponse = await axios.get(`${fileApiUrl}/faktory/pedidovendahistorico/${parseInt(cardData.pedido_number)}`);
        setHistoricoPedidoVendaFaktory(historicoPedidoVendaResponse.data);

        const itensPedidoVendaResponse = await axios.get(`${fileApiUrl}/faktory/pedidovendaitem/${parseInt(cardData.pedido_number)}`);
        setItensPedidoVendaFaktory(itensPedidoVendaResponse.data);
      } catch (error) {
        console.error('Erro ao buscar informações:', error);
      }
    };

    moduloFaktory();
  }, [cardData.pedido_number]);







  return (
    <div className='faktory-modal'>
      <div className='header-faktory-container'>
        <img className='logo-faktory-module-module' src={LogoFaktory} alt='Logo Faktory' />

        <label className='title-faktory-hader'>Em Testes!!!</label>
        <button onClick={() => setShowFaktoryModule(false)} className='btn-close-faktory-module'>x</button>
      </div>

      <div className='body-faktory-container'>
        <div className='faktory-container-pedido-venda'>
          <h3 className='title-faktory-module-container'>Dados do Pedido de Venda</h3>

          <div className='pedido-venda-faktory-container'>
            {pedidoVendaFaktory && (
              <>
                <div className='pedido-venda-item'>
                  <label>DOCUMENTO:</label> {pedidoVendaFaktory.NM_DOCUMENTO !== null ? pedidoVendaFaktory.NM_DOCUMENTO : 'N/A'}
                </div>

                <div className='pedido-venda-item'>
                  <label>ENDERECO:</label> {pedidoVendaFaktory.DS_ENDERECO !== null ? pedidoVendaFaktory.DS_ENDERECO : 'N/A'}
                </div>

                <div className='pedido-venda-item'>
                  <label>OBSERVACAO:</label> {pedidoVendaFaktory.DS_OBSERVACAO !== null ? pedidoVendaFaktory.DS_OBSERVACAO : 'N/A'}
                </div>

              </>
            )}
          </div>






        </div>


        <div className='faktory-container-pedido-venda'>
          <h3 className='title-faktory-module-container'>Histórico do Pedido de Venda</h3>
          <div className='pedido-venda-faktory-container'>
            {historicoPedidoVendaFaktory && historicoPedidoVendaFaktory.map((historico, index) => (
              <div key={index} className='pedido-venda-item'>
                <div>
                  {historico.DS_HISTORICO !== null ? historico.DS_HISTORICO : 'N/A'}
                </div>
              </div>
            ))}
          </div>

        </div>

        <div className='faktory-container-pedido-venda'>
          <h3 className='title-faktory-module-container'>Itens do Pedido de Venda</h3>
          <div className='pedido-venda-faktory-container'>
            {itensPedidoVendaFaktory && itensPedidoVendaFaktory.map((item, index) => (
              <div key={index} className='pedido-venda-item'>
                <div>
                  <label>QUANTIDADE:</label> {item.QT_PRODUTOCOM !== null ? item.QT_PRODUTOCOM : 'N/A'}
                </div>
                <div>
                  <label>DESCRIÇÃO:</label> {item.DS_COMPLEMENTAR !== null ? item.DS_COMPLEMENTAR : 'N/A'}
                </div>
                <div>
                  <label>VALOR:</label> {item.VL_UNITARIOCOM !== null ? item.VL_UNITARIOCOM.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'N/A'}
                </div>

              </div>
            ))}
          </div>

        </div>




        <div className='faktory-container-pedido-venda'>
          <h3 className='title-faktory-module-container'>Dados do Participante</h3>
          <div className='pedido-venda-faktory-container'>
            {participanteFaktory && (
              <>
                <div className='pedido-venda-item'>
                  <label>RAZÃO SOCIAL:</label> {participanteFaktory.DS_RAZAOSOCIAL || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>NOME FANTASIA:</label> {participanteFaktory.DS_NOMEFANTASIA || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>CEP:</label> {participanteFaktory.NM_CEP || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>ENDERECO:</label> {participanteFaktory.DS_ENDERECO || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>NÚMERO ENDERECO:</label> {participanteFaktory.NM_ENDERECO || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>COMPLEMENTO:</label> {participanteFaktory.DS_COMPLEMENTO || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>BAIRRO:</label> {participanteFaktory.DS_BAIRRO || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>CNPJ:</label> {participanteFaktory.NM_CNPJ || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>TELEFONE:</label> {participanteFaktory.NM_TELEFONE1 || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>TELEFONE:</label> {participanteFaktory.NM_TELEFONE2 || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>EMAIL:</label> {participanteFaktory.DS_WEBSITE || ''}
                </div>
                <div className='pedido-venda-item'>
                  <label>CONTATO PRINCIPAL:</label> {participanteFaktory.DS_CONTATOPRINCIPAL || ''}
                </div>
              </>
            )}
          </div>

        </div>



      </div>

      <div className='footer-faktory-container'></div>
    </div>
  );










};

export default Faktory;
