import React, { createContext, useContext, useState, useEffect } from 'react';

// API
import axios from 'axios';
import { apiUrl } from '../config/apiConfig';

import { useUser } from '../contexts/userContext';
import { useColumns } from '../contexts/columnsContext';



const CardContext = createContext();

export const useCard = () => useContext(CardContext);

export const CardProvider = ({ children }) => {

  const { user } = useUser();

  const { filteredColumnsBuscaLocal, setFilteredColumnsBuscaLocal, columns, setLoadingResult, setLoadingModal, dataInicial, setDataInicial, dataFinal, setDataFinal, tipoBuscaLocal, localSearchTerm, selectedAfilhados, showSharedCards, selectedEtiqueta, nivelBuscaCards, setNivelBuscaCards } = useColumns();


  const [openCloseCreateCard, setOpenCloseCreateCard] = useState(false)
  const openModalCreateCard = () => setOpenCloseCreateCard(!openCloseCreateCard);
  const [listCardsFiltereds, setListCardsFiltereds] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [openCloseUpdateUserModal, setOpenCloseUpdateUserModal] = useState(false)
  const [currentCardData, setCurrentCardData] = useState()

  const [openCloseUpdateCard, setOpenCloseUpdateCard] = useState(false)

  const [cards, setCards] = useState([]);

  const [previewSearchCards, setPreviewSearchCards] = useState([]);

  const [listNotifications, setListNotifications] = useState([]);

  const [openCloseHistoricModal, setOpenCloseHistoricModal] = useState(false)
  const [openCloseTarefasModal, setOpenCloseTarefasModal] = useState(false)
  const [openCloseCompartilharModal, setOpenCloseCompartilharModal] = useState(false)
  const [openCloseModuloEsquadriasModal, setOpenCloseModuloEsquadriasModal] = useState(false)
  const [openCloseAnexosModal, setOpenCloseAnexosModal] = useState(false)

  const [openClosePedidosModal, setOpenClosePedidosModal] = useState(false)


  const [tarefas, setTarefas] = useState([]);

  const [currentCardIdMessage, setCurrentCardIdMessage] = useState(null)
  const [openCloseModalMessenger, setOpenCloseModalMessenger] = useState(false);



  const [currentModuleCard, setCurrentModuleCard] = useState()




  // FILTRO DE BUSCA LOCAL

  const [listaCardsFiltrados, setListaCardsFiltrados] = useState([]);

  const addHistoricoCardContext = async (currentHistoric, cardId, userId) => {

    if (!currentHistoric && currentHistoric == '')
      return;

    try {
      const payload = {
        card_id: cardId, // assuming idCard is available in the component's props
        user_id: userId, // from useUser context
        action_type: 'Update', // or any other type depending on the context
        description: currentHistoric,
        empresa_id: user.empresa_id
        //card_status: currentCardData.status // assuming cardData has a status field
      };
      const response = await axios.post(`${apiUrl}/card/add-history`, payload);

    } catch (error) {
      console.error('Error adding card history:', error);
    }
  };









  useEffect(() => {
    if (!user)
      return
    const today = new Date();
    const endOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59); // Final do dia de hoje
    const lastYear = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

    setDataInicial(lastYear.toISOString().split('T')[0]);
    setDataFinal(endOfToday.toISOString().split('T')[0]);
    fetchCards(lastYear.toISOString().split('T')[0], endOfToday.toISOString().split('T')[0])
  }, [user, nivelBuscaCards]);







  const fetchCards = async (dataInicial, dataFinal, dataType = 'created_at') => {
    try {
      setLoadingModal(true);
      setLoadingResult('Carregando...');
  
      // Pausa para garantir que o loading seja renderizado antes da requisição
      await new Promise(resolve => setTimeout(resolve, 0));

      
  
      let url = '';

      
  
      if (nivelBuscaCards === 0) {
        url = `${apiUrl}/card/find/${user.id}/${user.empresa_id}`;
      } else if (nivelBuscaCards === 1) {
        url = `${apiUrl}/card/find/tarefas/user/${user.id}/${user.empresa_id}`;
      } else if (nivelBuscaCards === 2) {
        url = `${apiUrl}/card/find/tarefas/${user.id}/${user.empresa_id}`;
      }
  
      const response = await axios.get(url, {
        params: {
          dataInicial,
          dataFinal,
          dataType,
        },
      });

     // console.log(response.data)
  
      setCards(response.data);
      setLoadingModal(false);
      setLoadingResult('');
    } catch (error) {
      console.error('Erro ao buscar cards:', error);
      setLoadingResult('Erro ao Carregar Cards!');
      setLoadingModal(false);
    }
  };
  

  useEffect(() => {
    

      fetchCards(dataInicial, dataFinal, 'created_at');

  }, [nivelBuscaCards]);
  








  
  // useEffect(() => {




  //     setListaCardsFiltrados(cards);
  //     setFilteredColumnsBuscaLocal(columns)



  // }, [localSearchTerm, tipoBuscaLocal, cards, selectedAfilhados, showSharedCards, selectedEtiqueta, filteredColumnsBuscaLocal]);





  useEffect(() => {




    if (localSearchTerm === '' || localSearchTerm === null) {


      let filteredA = cards;

      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));

      
      setListaCardsFiltrados(filteredE);
      setFilteredColumnsBuscaLocal(columns)

    }

    if (tipoBuscaLocal === 'name' && (localSearchTerm != '' && localSearchTerm != null)) {

      let filteredA = cards.filter(card =>
        card.name && card.name.toString().toLowerCase().trim().includes(localSearchTerm.toString().toLowerCase().trim())
      );


      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);


    }

    if (tipoBuscaLocal === 'fone' && (localSearchTerm != '' && localSearchTerm != null)) {
      let filteredA = cards.filter(card =>
        card.fone && card.fone.toString().toLowerCase().trim().includes(localSearchTerm.toString().toLowerCase().trim())
      );
      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }

    if (tipoBuscaLocal === 'valor' && (localSearchTerm != '' && localSearchTerm != null)) {
      let filteredA = cards.filter(card =>
        card.cost_value && card.cost_value.toString().toLowerCase().trim().includes(localSearchTerm.toString().toLowerCase().trim())
      );
      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);


      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }

    if (tipoBuscaLocal === 'orcamento' && (localSearchTerm != '' && localSearchTerm != null)) {
      let filteredA = cards.filter(card =>
        card.document_number && card.document_number.toString().toLowerCase().trim().includes(localSearchTerm.toString().toLowerCase().trim())
      );
      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }

    if (tipoBuscaLocal === 'pedido' && (localSearchTerm != '' && localSearchTerm != null)) {
      let filteredA = cards.filter(card =>
        card.pedido_number && card.pedido_number.toString().toLowerCase().trim().includes(localSearchTerm.toString().toLowerCase().trim())
      );
      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }

    if (tipoBuscaLocal === 'city' && (localSearchTerm != '' && localSearchTerm != null)) {
      let filteredA = cards.filter(card =>
        card.city && card.city.toString().toLowerCase().trim().includes(localSearchTerm.toString().toLowerCase().trim())
      );
      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }

    if (tipoBuscaLocal === 'column' && (localSearchTerm != '' && localSearchTerm != null)) {
      let filteredA = cards.filter(card =>
        card.column_id && card.column_id.toString().toLowerCase().trim().includes(localSearchTerm.toString().toLowerCase().trim())
      );
      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }

    if (tipoBuscaLocal === 'origem' && (localSearchTerm != '' && localSearchTerm != null)) {
      let filteredA = cards.filter(card =>
        card.origem && card.origem.toString().toLowerCase().trim().includes(localSearchTerm.toString().toLowerCase().trim())
      );
      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }

    if (tipoBuscaLocal === 'responsavel' && (localSearchTerm != '' && localSearchTerm != null)) {
      let filteredA = cards.filter(card =>
        card.responsavel && card.responsavel.toString().toLowerCase().trim().includes(localSearchTerm.toString().toLowerCase().trim())
      );
      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }

    if (tipoBuscaLocal === 'vendidos') {
      let filteredA = cards.filter(card => card.status === 'Vendido');

      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }


    if (tipoBuscaLocal === 'perdidos') {
      let filteredA = cards.filter(card => card.status === 'Perdido');

      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }




    if (tipoBuscaLocal === 'frio') {
      let filteredA = cards.filter(card => card.temperatura_card === 1);

      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }

    if (tipoBuscaLocal === 'morno') {
      let filteredA = cards.filter(card => card.temperatura_card === 2);

      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }

    if (tipoBuscaLocal === 'quente') {
      let filteredA = cards.filter(card => card.temperatura_card === 3);

      let filteredB = filteredA.filter(card => selectedAfilhados.includes(card.entity_id));

      let filteredC = filteredA.filter(card => card.compartilhamento === true && showSharedCards);

      let filteredD = [...filteredB, ...filteredC];

      let filteredE = filteredD.filter(card => !selectedEtiqueta || card.etiqueta_id === parseInt(selectedEtiqueta));


      setListaCardsFiltrados(filteredE);

      // Atualiza as colunas para mostrar apenas aquelas com cards compatíveis com o filtro
      const columnsWithMatchingCards = columns.filter(column =>
        filteredE.some(card => card.column_id === column.id)
      );
      setFilteredColumnsBuscaLocal(columnsWithMatchingCards);
    }




  }, [localSearchTerm, tipoBuscaLocal, cards, selectedAfilhados, showSharedCards, selectedEtiqueta]);


  const contextValue = {
    openCloseCreateCard,
    openModalCreateCard,
    listCardsFiltereds, setListCardsFiltereds,
    searchTerm, setSearchTerm,
    currentCardData, setCurrentCardData,
    openCloseUpdateCard, setOpenCloseUpdateCard,
    cards, setCards,
    previewSearchCards, setPreviewSearchCards,
    listNotifications, setListNotifications,
    openCloseHistoricModal, setOpenCloseHistoricModal,
    openCloseTarefasModal, setOpenCloseTarefasModal,
    openCloseCompartilharModal, setOpenCloseCompartilharModal,
    tarefas, setTarefas,
    openCloseModuloEsquadriasModal, setOpenCloseModuloEsquadriasModal,
    addHistoricoCardContext,
    currentCardIdMessage, setCurrentCardIdMessage,
    openCloseModalMessenger, setOpenCloseModalMessenger,
    openCloseAnexosModal, setOpenCloseAnexosModal,
    fetchCards,
    currentModuleCard, setCurrentModuleCard,
    openClosePedidosModal, setOpenClosePedidosModal,
    listaCardsFiltrados, setListaCardsFiltrados,



  };

  return (
    <CardContext.Provider value={contextValue}>
      {children}
    </CardContext.Provider>
  );
};
